/*
Body
*/

ion-content.main-content {
  --background: var(--ion-item-background, var(--ion-color-light, #fff));
}


/* 
Menu
*/
/* .list-md {
  background: inherit !important;
} */

ion-menu ion-content,
ion-menu ion-item {
  --background: var(--ion-color-medium); /* Cambia el color según tu preferencia */
  --ion-background-color: var(--ion-color-medium);
  --ion-item-background: var(--ion-color-medium);
  --color: var(--ion-color-medium-contrast);
}

ion-menu ion-accordion div[slot="content"] {
  --background: rgba(var(--ion-color-medium-rgb), 0.20); /* Fondo más claro para subitems */
  background: rgba(var(--ion-color-medium-rgb), 0.20);
  --ion-background-color: rgba(var(--ion-color-medium-rgb), 0.20);
  --ion-item-background: rgba(var(--ion-color-medium-rgb), 0.20);

  ion-item {
    --background: transparent;
    background: transparent;
    --ion-background-color: transparent;
    --ion-item-background: transparent;
  }
}

ion-menu ion-accordion.accordion-expanded ion-item[slot="header"] {
  --background: rgba(var(--ion-color-medium-rgb), 0.25); /* Fondo más claro para subitems */
  background: rgba(var(--ion-color-medium-rgb), 0.25); /* Fondo más claro para subitems */
  --ion-background-color: rgba(var(--ion-color-medium-rgb), 0.25);
  --ion-item-background: rgba(var(--ion-color-medium-rgb), 0.25);
}

ion-item.selected {
  --background: var(--ion-color-primary-tint) !important;
  --color: var(--ion-color-primary-contrast) !important;
}

.ion-accordion-toggle-icon {
  color: var(--ion-color-primary-contrast);
}

ion-menu ion-label,
ion-menu ion-text {
  --color: var(--ion-color-light, #ffffff); /* Asegura que el texto sea claro sobre fondo oscuro */
}

ion-menu ion-item {
  --inner-padding-end: 0px !important;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header, ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item ion-label {
  font-weight: 400;
  @apply !text-sm;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: var(--ion-color-medium);
}

ion-menu.ios ion-item .selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;
  color: var(--ion-color-medium-shade);
}

/* 
split-pane
*/

ion-split-pane {
  --side-width: 150px;
  --side-max-width: 300px;
}

ion-split-pane {
  --side-width: 300px !important;
  --side-max-width: 300px !important;
  ion-menu {
    --width: 300px !important;
    --max-width: 300px !important;
  }
}

/* @media screen and (min-width: 576px) and (max-width: 992px) {
  ion-split-pane {
    --side-width: 68px !important;
    --side-max-width: 68px !important;
    --side-min-width: 68px !important;
    ion-menu {
      --width: 68px !important;
      --max-width: 68px !important;
      --min-width: 68px !important;
    }
  }
} */

@media screen and (min-width: 992px) {
  ion-split-pane {
    --side-width: 320px !important;
    --side-max-width: 320px !important;
    
    ion-menu {
      --width: 320px !important;
      --max-width: 320px !important;
    }
  }
}

.header-title-logo {
  @apply flex place-content-center;
}


/* 
popover
*/
ion-popover.wide {
  --min-width: 92vw;
}
@media screen and (min-width: 400px) {
  ion-popover.wide {
    --min-width: 80vw;
  }
}
@media screen and (min-width: 768px) {
  ion-popover.wide {
    --min-width: 70vw;
  } 
}

ion-popover.user-popover {
  --min-width: 280px; 
}


/*
Logger Modal
*/
ion-modal.logger-modal {
  --height: 35%;
  --width: 70%;
}
@media screen and (min-width: 768px) {
  ion-modal.logger-modal {
    --height: 35%;
    --width: 45%;
  }
}


/*
Modal Sheet
*/

/* Modal Sheet: medium */
ion-modal.medium {
  --width: 94vw;
  --height: 96vh;
}
@media screen and (min-width: 1024px) {
  ion-modal.medium {
    --width: 60vw;
    --height: 96vh;
  }
}

/* Modal Sheet: medium-wide */
ion-modal.medium-wide {
  --width: 94vw;
  --height: 96vh;
}
@media screen and (min-width: 1024px) {
  ion-modal.medium-wide {
    --width: 80vw;
    --height: 96vh;
  }
}

/* Modal Sheet: wide */
ion-modal.wide {
  --width: 94vw;
  --height: 96vh;
}
@media screen and (min-width: 1024px) {
  ion-modal.wide {
    --width: 96vw;
    --height: 95vh;
  }
}